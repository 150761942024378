import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import { moment } from './config/monent';
import Header from "components/Header";
import { FacebookProvider } from "react-facebook";
import SEO from "components/SEO";
import { useLocation } from "react-use";
import ReactGA from "react-ga";
import { fetchUser } from "actions/user";
import { useHistory, Redirect } from "react-router-dom";
import { openModal, closeModal } from "actions/modal";
import {
  getProfileData,
  getUserAuthenticated,
  getUserDetails,
} from "selectors/user";
import EmailLoginModal from "components/Auth/EmailLoginModal";
import SignupModal from "components/Auth/SignupModal";
import SocialMediaLoginModal from "components/Auth/SocialMediaLoginSignup";
import ForgotPasswordModal from "components/Auth/ForgotPasswordModal";
import ConnectedSocketProvider from "utils/ConnectedSocketProvider";

// ReactGA.initialize(process.env.RAZZLE_GA_ID);

const Layout = ({
  children,
  isAuthenticated,
  openModal,
  closeModal,
  fetchUser,
}) => {
  const history = useHistory();

  useEffect(() => {
    fetchUser();
  }, []);

  let location = useLocation();
  // useEffect(() => {
  //   ReactGA.pageview(location.pathname);
  // }, [location]);

  return (
    <FacebookProvider appId={process.env.RAZZLE_FACEBOOK_ID}>
      <Helmet>
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="alternate icon" href="/favicon.ico" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=0.86, width=device-width"
        />
        {/* <script>
          {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '557243501834193');
        fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=557243501834193&ev=PageView&noscript=1"
          />`}
        </noscript> */}
      </Helmet>
      {/* <SEO /> */}

      <ConnectedSocketProvider>
        {children}
        {!isAuthenticated && (
          <>
            <EmailLoginModal closeModal={closeModal} openModal={openModal} />
            <SignupModal closeModal={closeModal} openModal={openModal} />
            <SocialMediaLoginModal
              closeModal={closeModal}
              openModal={openModal}
            />
            <ForgotPasswordModal
              closeModal={closeModal}
              openModal={openModal}
            />
            {/* <ResetPasswordModal closeModal={closeModal} openModal={openModal} /> */}
          </>
        )}
      </ConnectedSocketProvider>
    </FacebookProvider>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getUserAuthenticated(state),
  profileData: getProfileData(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  closeModal,
  openModal,
  fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
