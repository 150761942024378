import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "actions/user";
import { openModal, closeModal } from "actions/modal";
import { SOCIAL_LOGIN_MODAL_ID } from "consts/modalIds";
import SocialMediaLoginSignup from "./Auth/SocialMediaLoginSignup";
import {
  getUserAuthenticated,
  getUserDetails,
  isUserAdmin,
} from "selectors/user";
import { useApi } from "react-facebook";

const Header = ({ isAuthenticated, openModal, logout, userData, isAdmin }) => {
  const { t } = useTranslation();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [blogsOpen, setBlogsOpen] = useState(false);
  const history = useHistory();

  const [fbApi] = useApi();

  const { i18n } = useTranslation();

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  const toggleOpenBlogs = () => {
    setBlogsOpen(!blogsOpen);
  };

  const openLoginModal = () => {
    openModal(SOCIAL_LOGIN_MODAL_ID);
  };

  const setLang = useCallback((lang) => {
    localStorage.setItem("i18nextLng", lang);
    i18n.changeLanguage(lang);
  }, []);

  const signout = async () => {
    await logout();

    fbApi.logout();
    // fbApi.getLoginStatus((status) => {
    //   console.log(status);
    // });
    history.push("/");
  };

  return (
    <>
      <div className="bg-blue">
        <div className="container mx-auto h-20 flex justify-between content-center font-mont">
          <div className="flex items-center text-white">
            {/* <a href="https://lead.org.mk/" target="_blank">
              <img src={leadLogo} className="h-14" />
            </a> */}
            <Link to="/" className="p-5 text-base active:bg-lightBlue">
              {t("home")}
            </Link>
            {isAuthenticated && (
              <Link to="/games" className="p-5 text-base">
                {t("play")}
              </Link>
            )}
            {isAdmin && (
              <Link to="/users" className="p-5 text-base">{t("users")}</Link>
            )}
            {isAdmin && (
              <Link to="/sessions" className="p-5 text-base">{t("sessions")}</Link>
            )}
            {isAdmin && (
              <Link to="/leaderboardPeriod" className="p-5 text-base">{t("leaderboard")}</Link>
            )}
            <Link
              to="/instructions"
              className="p-5 text-base active:bg-lightBlue font-mont"
            >
              {t("instructions")}
            </Link>
            <div
              className="flex items-center justify-center w-auto space-x-3"
              onClick={toggleOpenBlogs}
            >
              <Link to="#" className="text-white text-mont">
                {t("inform")}
              </Link>
              <div
                className={`absolute mt-96 t-20 l-0 b-10 w-64 p-3 bg-blue rounded-md shadow z-50 ${
                  blogsOpen ? "block" : "hidden"
                }`}
              >
                <div className="flex flex-col font-poppinsRegular">
                  <Link to="/info/1" className="mt-3">
                    {t("menu_blog1")}
                  </Link>
                  <Link to="/info/2" className="mt-3">
                    {t("menu_blog2")}
                  </Link>
                  <a
                    href="https://sobranie.mk/"
                    target="_blank"
                    className="mt-3"
                  >
                    {" "}
                    {t("link1")}
                  </a>
                  <a href="https://vlada.mk/" target="_blank" className="mt-3">
                    {t("link2")}
                  </a>
                  <a
                    href="https://izbornaarhiva.mk/"
                    target="_blank"
                    className="mt-3 mb-3"
                  >
                    {t("link3")}
                  </a>
                </div>
              </div>
            </div>
            <Link
              to="/blogs"
              className="p-5 text-base active:bg-lightBlue font-mont"
            >
              {t("educate")}
            </Link>
          </div>

          <div className="flex items-center justify-center w-auto space-x-3">
            <button
              className="font-mont text-white focus:outline-none"
              onClick={() => setLang("mk")}
            >
              MK
            </button>
            <button
              className="font-mont text-white focus:outline-none"
              onClick={() => setLang("al")}
            >
              AL
            </button>
            <div className="w-4"></div>

            {isAuthenticated && userData.isFetched && (
              <>
                <button onClick={toggleFiltersOpen}>
                  <a href="#" className="text-white text-mont">
                    {t("profile")}
                  </a>
                </button>
                {/* <a
                  href="https://www.kas.de/en/web/nordmazedonien"
                  target="_blank"
                >
                  <img src={konradLogo} className="h-14" />
                </a> */}
                <div
                  className={`absolute mt-52 t-32 l-0 b-10 w-64 p-3 h-40 bg-whiteSmoke rounded-md shadow ${
                    filtersOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="flex flex-col font-poppinsRegular">
                    <Link to="/profile" className="mt-3">
                      {t("my_profile")}
                    </Link>
                    <Link to="/editProfile" className="mt-3">
                      {t("edit_profile")}
                    </Link>
                    <Link onClick={signout} className="mt-3">
                      {t("logout")}
                    </Link>
                  </div>
                </div>
              </>
            )}
            {!isAuthenticated && (
              <>
                <button
                  className="font-mont text-white focus:outline-none"
                  onClick={openLoginModal}
                >
                  {t("login")}
                </button>

                {/* <a
                  href="https://www.kas.de/en/web/nordmazedonien"
                  target="_blank"
                >
                  <img src={konradLogo} className="h-14" />
                </a> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getUserAuthenticated(state),
  userData: getUserDetails(state),
  isAdmin: isUserAdmin(state),
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
